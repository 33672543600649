// Import libraries
import React from 'react';
import styled from 'styled-components';


// Import global styles
import GlobalStyle from '../styles/global';


// Import component
import Layout from '../layout/Layout';
import SEO from '../layout/SEO';


// Component styles
const StyledContainer = styled.div`
    display: grid;
    grid-template-rows: repeat(2, max-content);
    grid-row-gap: 2rem;
    padding: 10rem var(--padding-right) 10rem var(--padding-left);
`;


// Create component
const FreeQuotePage = () => {
    return (
        <React.Fragment>
            <GlobalStyle />
            <SEO title="Gratis Offerte" />
            <Layout>
                <StyledContainer>
                    <h1>Gratis Offerte</h1>

                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Animi sit quaerat perferendis expedita officia aspernatur debitis, veritatis obcaecati molestiae explicabo accusamus similique ex quam modi quas eligendi, suscipit rem tempora! Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur cumque nobis atque fugit harum tempora magni quasi accusantium commodi, minima rerum porro earum repudiandae perspiciatis voluptatibus? Illo cumque iste necessitatibus.</p>
                </StyledContainer>
            </Layout>
        </React.Fragment>
    );
};


// Export component by default
export default FreeQuotePage;